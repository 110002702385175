import { faRemove, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Controller } from "react-hook-form";

const Dropzone: FC<{
    multiple?: boolean;
    onDrop: any;
    maxFiles: number;
    formValue: any;
}> = ({ multiple, onDrop, maxFiles = 2, formValue, ...rest }) => {
    const [files, setFiles] = useState([]);

    const maxFilesValidator = () => {
        if (files.length > maxFiles - 1) {
            return {
                code: "too-many-files",
                message: `${maxFiles} files are the maximum number of files you can drop here`,
            };
        }

        return null;
    };

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        validator: maxFilesValidator,
        maxSize: 5 * 1024 * 1024,
        accept: {
            "image/png": [".png"],
            "image/jpeg": [".jpg", ".jpeg"],
            "application/pdf": [".pdf"],
        },
        maxFiles,
        multiple,
        onDrop: (acceptedFiles) => {
            const existingFiles = formValue || [];
            const newFiles = [...existingFiles, ...acceptedFiles];
            onDrop(newFiles);
            setFiles(
                newFiles.map((file) => {
                    if (typeof file === "string") {
                        return {
                            preview: file,
                            name: file.split("/").pop(),
                            isUrl: true,
                        };
                    }
                    return {
                        ...file,
                        preview: URL.createObjectURL(file),
                    };
                }),
            );
        },
        ...rest,
    });

    const removeFile = (fileToRemove) => {
        const updatedFiles = formValue.filter((file: any) =>
            typeof file === "string" ? file !== fileToRemove.preview : file.path !== fileToRemove.path,
        );
        setFiles(
            updatedFiles.map((f) => {
                if (typeof f === "string") {
                    return {
                        preview: f,
                        name: f,
                        isUrl: true,
                        path: f,
                    };
                }
                return {
                    ...f,
                    preview: URL.createObjectURL(f),
                };
            }),
        );
        onDrop(updatedFiles);
    };

    const fileRejectionItems = fileRejections.map(({ errors }, index) => (
        <div key={index} className="p-2">
            <div>
                {errors.map((e) => (
                    <div className="text-sm text-red-500" key={e.code}>
                        {e.message}
                    </div>
                ))}
            </div>
        </div>
    ));

    useEffect(() => {
        if (formValue) {
            const initialFiles = formValue.map((file) => {
                if (typeof file === "string") {
                    return {
                        preview: file,
                        name: file,
                        isUrl: true,
                        path: file,
                    };
                }
                return {
                    ...file,
                    preview: URL.createObjectURL(file),
                };
            });
            setFiles(initialFiles);
        }
    }, [formValue]);

    useEffect(() => {
        return () => files.forEach((file) => !file.isUrl && URL.revokeObjectURL(file.preview));
    }, [files]);

    const handleOpenFile = (e, file) => {
        e.stopPropagation();
        if (file.isUrl) {
            window.open(file.preview, "_blank");
        }
    };
    return (
        <section className="max-h-50 container min-h-20  border-2 border-dashed border-r-rc-gray-light bg-rc-gray-light">
            <div {...getRootProps()}>
                <div className="flex items-center justify-center text-center">
                    <div>
                        <p>Drag & drop some files here, or click to select files</p>
                        <em className="inline-block">({maxFiles} files are the maximum number of files you can drop here)</em>
                        <FontAwesomeIcon icon={faUpload} />
                        <input {...getInputProps()} />
                    </div>
                </div>
                <aside className="flex gap-2 p-2">
                    {files.map((file) => {
                        const filePath = file.path || file.name;
                        const isPdf = filePath?.includes(".pdf");
                        return (
                            <div key={file.name}>
                                <div className="relative">
                                    <FontAwesomeIcon
                                        size="lg"
                                        icon={faRemove}
                                        className="absolute right-0 top-0"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            removeFile(file);
                                        }}
                                    />
                                    {isPdf ? (
                                        <div
                                            title={file.name}
                                            className="truncate text-rc-pink hover:text-rc-blue"
                                            onClick={(e) => handleOpenFile(e, file)}
                                        >
                                            {file.isUrl ? filePath.slice(-13) : filePath}
                                        </div>
                                    ) : (
                                        <img
                                            className="size-20"
                                            alt="preview"
                                            src={file.preview}
                                            onLoad={() => {
                                                URL.revokeObjectURL(file.preview);
                                            }}
                                            onClick={(e) => handleOpenFile(e, file)}
                                        />
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </aside>
                {fileRejectionItems}
            </div>
        </section>
    );
};

const DropzoneField = ({ name, multiple = true, control, label, maxFiles = 2, ...rest }) => {
    return (
        <Controller
            render={({ field: { onChange, value, ...fieldRest }, fieldState: { error } }) => (
                <div className="mb-4">
                    {label && (
                        <label htmlFor={name} className="mb-2 block text-sm font-bold text-white">
                            {label}
                        </label>
                    )}
                    <Dropzone
                        formValue={value}
                        maxFiles={maxFiles}
                        multiple={multiple}
                        onDrop={(files) => {
                            onChange(files);
                        }}
                        {...rest}
                        {...fieldRest}
                    />
                    {error && <span className="text-sm text-red-500">{error.message}</span>}
                </div>
            )}
            name={name}
            control={control}
        />
    );
};

export default DropzoneField;
