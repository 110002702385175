export interface INetwork {
    name: string;
    secondaryName?: string;
    internalName?: string;
    iconUrl: string;
    iconHeaderUrl?: string;
    logoUrl: string;
    loginUrl?: string;
    loginFunction?: any;
    ayrsharePlatform?: string;
    iconHeaderUrlColored?: string;
}

interface INetworkConfig {
    realclixx: INetwork;
    linkedin: INetwork;
    twitter: INetwork;
    ebay: INetwork;
    amazon: INetwork;
    youTube: INetwork;
    instagrambasic: INetwork;
    facebook: INetwork;
    spotify: INetwork;
    pinterest: INetwork;
    apple: INetwork;
    twitch: INetwork;
    tiktok: INetwork;
    ayrshare: INetwork;
    reddit: INetwork;
    telegram: INetwork;
    fbg: INetwork;
    gmb: INetwork;
}

export const NetworksArray = [
    {
        name: "Realclixx",
        iconUrl: "/icon_transparent.png",
        logoUrl: "/logo_white.png",
        loginUrl: "/",
    },
    {
        name: "Twitter",
        iconUrl: "/media-icons/anim-grid/twitter-solid-v2.png",
        logoUrl: "",
        loginUrl: "/api/twitter",
    },
    {
        name: "Instagram",
        iconUrl: "/media-icons/anim-grid/instagram.png",
        logoUrl: "",
        loginUrl: "/api/instagram",
    },
    {
        name: "Facebook",
        iconUrl: "/media-icons/anim-grid/facebook.png",
        logoUrl: "",
        loginUrl: "/api/facebook/network",
    },
];

const Networks: INetworkConfig = {
    realclixx: {
        name: "Realclixx",
        iconUrl: "/icon_transparent.png",
        logoUrl: "/logo_white.png",
        loginUrl: "/",
    },
    telegram: {
        name: "Telegram",
        iconUrl: "/media-icons/anim-grid/telegram.png",
        logoUrl: "/media-icons/svgs/telegram.svg",
        ayrsharePlatform: "telegram",
    },
    reddit: {
        name: "Reddit",
        iconUrl: "/media-icons/anim-grid/reddit.png",
        logoUrl: "/media-icons/svgs/reddit.svg",
        ayrsharePlatform: "reddit",
    },
    fbg: {
        name: "Facebook Groups",
        iconUrl: "/media-icons/anim-grid/fb-groups.png",
        logoUrl: "/media-icons/svgs/fb-groups.svg",
        ayrsharePlatform: "fbg",
    },
    gmb: {
        name: "Google My Business",
        iconUrl: "/media-icons/anim-grid/google-my-business.png",
        logoUrl: "/media-icons/svgs/google-my-business.svg",
        ayrsharePlatform: "gmb",
    },
    linkedin: {
        name: "LinkedIn",
        iconUrl: "/media-icons/anim-grid/linkedin.png",
        logoUrl: "/",
        loginUrl: "/api/linkedin",
        ayrsharePlatform: "linkedin",
    },
    twitter: {
        name: "Twitter",
        iconUrl: "/media-icons/anim-grid/twitter-v2.png",
        iconHeaderUrl: "/media-icons/anim-grid/twitter-solid-v2.png",
        logoUrl: "",
        loginUrl: "/api/twitter",
        ayrsharePlatform: "twitter",
        iconHeaderUrlColored: "/media-icons/anim-grid/twitter-icon-color.png",
    },
    ebay: {
        name: "ebay",
        iconUrl: "",
        logoUrl: "/media-icons/ebay.png",
        loginUrl: "",
    },
    amazon: {
        name: "Amazon",
        iconUrl: "/media-icons/amazon.png",
        logoUrl: "",
        loginUrl: "",
    },
    youTube: {
        name: "YouTube",
        internalName: "Youtube",
        iconUrl: "/media-icons/anim-grid/youtube.png",
        iconHeaderUrl: "/media-icons/anim-grid/youtube.png",
        logoUrl: "/media-icons/svgs/youtube_logo_weiss.svg",
        loginUrl: "/api/youTube",
        ayrsharePlatform: "youtube",
        iconHeaderUrlColored: "/media-icons/svgs/youtube_colored.svg",
    },
    instagrambasic: {
        name: "Instagram",
        iconUrl: "/media-icons/anim-grid/instagram.png",
        iconHeaderUrl: "/media-icons/svgs/instagram.svg",
        logoUrl: "/media-icons/instagram-icon-filled.webp",
        loginUrl: "/api/instagram",
        ayrsharePlatform: "instagram",
        iconHeaderUrlColored: "/media-icons/svgs/instagram_colored.svg",
    },
    facebook: {
        name: "Facebook",
        iconUrl: "/media-icons/anim-grid/facebook.png",
        iconHeaderUrl: "/media-icons/svgs/facebook.svg",
        logoUrl: "",
        loginUrl: "/api/facebook/network",
        ayrsharePlatform: "facebook",
        iconHeaderUrlColored: "/media-icons/svgs/facebook_colored.svg",
    },
    spotify: {
        name: "Spotify",
        iconUrl: "/media-icons/anim-grid/spotify.png",
        iconHeaderUrl: "/media-icons/svgs/spotify.svg",
        logoUrl: "",
        loginUrl: "/api/spotify",
        iconHeaderUrlColored: "/media-icons/svgs/spotify_colored.svg",
    },
    apple: {
        name: "Apple",
        secondaryName: "Apple Music",
        iconUrl: "/media-icons/anim-grid/applemusic.png",
        iconHeaderUrl: "/media-icons/svgs/apple.svg",
        logoUrl: "",
        internalName: "Apple_Music",
        iconHeaderUrlColored: "/media-icons/svgs/apple_colored.svg",
    },
    pinterest: {
        name: "Pinterest",
        iconUrl: "/media-icons/anim-grid/pinterest.png",
        iconHeaderUrl: "/media-icons/svgs/pinterest_white.svg",
        logoUrl: "/media-icons/svgs/pinterest.svg",
        loginUrl: "/api/pinterest",
        ayrsharePlatform: "pinterest",
        iconHeaderUrlColored: "/media-icons/svgs/pinterest_colored.svg",
    },
    twitch: {
        name: "Twitch",
        internalName: "twitch",
        iconUrl: "/media-icons/twitch.png",
        logoUrl: "/media-icons/svgs/twitch_logo_white.svg",
        loginUrl: "/api/twitch",
    },
    tiktok: {
        name: "TikTok",
        iconUrl: "/media-icons/svgs/tik-tok-circle.svg",
        logoUrl: "/media-icons/svgs/tik-tok-circle.svg",
        loginUrl: "/api/tiktok",
        ayrsharePlatform: "tiktok",
    },
    ayrshare: {
        name: "Ayrshare",
        iconUrl: "/media-icons/anim-grid/ayrshare.png",
        logoUrl: "/media-icons/ayrshare-icon.png",
        loginUrl: "/",
    },
};

export default Networks;
